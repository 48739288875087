import React from "react";
import { graphql } from "gatsby";
import List from "./List";

function ListNews({ data, pageContext }) {
  return <List nodes={data.allNodeMeeting} pageContext={pageContext} />;
}

export default ListNews;

export const query = graphql`
  query meetingsQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(filter: { frontmatter: { slug: { eq: "meetings" } } }) {
      nodes {
        frontmatter {
          title
        }
      }
    }
    allNodeMeeting(
      limit: $limit
      skip: $skip
      sort: { fields: field_date, order: DESC }
    ) {
      edges {
        node {
          drupal_id
          field_date(formatString: "MMMM Do, YYYY")
          title
          body {
            processed
            summary
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
